@mixin list-unstyled (
  $no-margin: true
) {
  list-style-type: none;
  padding-left: 0;

  @if $no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@mixin list-inline (
  $space: false,
  $no-margin: true
) {
  @include list-unstyled($no-margin);

  & > li {
    display: inline-block;
  }

  @if $space {
    & > li + li {
      margin-left: $space;
    }
  }
}

@mixin breakpoint ($point) {
  @if $point == desktop {
    @media (min-width: rem(1120)) { @content; }
  }
  @else if $point == laptop {
    @media (min-width: rem(1024)) { @content; }
  }
  @else if $point == tablet {
    @media (min-width: rem(800)) { @content; }
  }
  @else if $point == phablet {
    @media (min-width: rem(600))  { @content; }
  }
  @else if $point == mobileonly {
    @media (max-width: rem(600))  { @content; }
  }
}

@mixin button-unstyled {
  border: none;
  background-color: transparent;

  appearance: none;
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  &:active, &:focus {
    outline: 0;
  }
}

@mixin hide-text {
  text-indent: -999px;
  white-space: nowrap;
  overflow: hidden;
}

@mixin bg-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
}
