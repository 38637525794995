$bar-width: 20px;
$bar-height: 2px;
$bar-spacing: 6px;
$bar-transition-time: 300ms;

.Hamburger {
	position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;
}

.Hamburger__bar,
.Hamburger__bar:after,
.Hamburger__bar:before {
  width: $bar-width;
	height: $bar-height;
}

.Hamburger__bar {
	position: relative;
	transform: translateY($bar-spacing);
	background: currentColor;
  border-radius: $bar-height;
	transition: all 0ms $bar-transition-time;
}

.MobileNav__toggle.is-active .Hamburger__bar {
  background: rgba(255, 255, 255, 0);
}

.Hamburger__bar:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: currentColor;
  border-radius: $bar-height;
	transition: bottom $bar-transition-time $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1), transform $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1);
}

.Hamburger__bar:after {
	content: '';
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: currentColor;
  border-radius: $bar-height;
	transition: top $bar-transition-time $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1), transform $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1);
}

.MobileNav__toggle.is-active .Hamburger__bar:after {
	top: 0;
	transform: rotate(45deg);
	transition: top $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1), transform $bar-transition-time $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1);;
}

.MobileNav__toggle.is-active .Hamburger__bar:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1), transform $bar-transition-time $bar-transition-time cubic-bezier(0.23, 1, 0.32, 1);;
}
