/*

Typography

*/

$base-font: 'museo-sans', sans-serif;
$font-size: 16px;
$font-size-desktop: 1.125rem; // = 18px

$heading-font: $base-font;
$body-font: $base-font;

$font-weight-normal: 300;
$font-weight-semibold: 500;
$font-weight-bold: 700;

/*

Colors

*/

$teal: #126f7d;
$white-gold: #f9f9f9;
$silver: #eff2f3;
$charcoal: #424242;
$cyan: #73f9fa;
$muted: #a7a7a7;

$brand-primary: $teal;
$text-color: $charcoal;

/*

Breakpoints

*/

$bp-xs: 480px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xlg: 1600px;

/*

Space

*/

$space-1: .5rem;
$space-2: 1rem;
$space-3: 2rem;
$space-4: 4rem;
$space-5: 8rem;

/*

Selectors

*/

$headings: 'h1, h2, h3, h4, h5, h6';
