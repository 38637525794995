/*

Header

*/

.about-header-text {
  padding: 0 40px 40px;
  text-align: center;

  > h1 {
    max-width: 800px;
    margin: 0 auto;
  }
}

.About__header {
  position: relative;
  background-image: url('/images/about/header-bg.svg'); // fallback
  background-repeat: no-repeat;
  background-position: 50% 80px;
  background-size: 150%;

  @include breakpoint(tablet) {
    background-position: 50% 100px;
    background-size: contain;
    background-image: url('/images/about/header-bg-desktop.svg');
    height: 45vw;
  }

  @include breakpoint(desktop) {
    height: 40vw;
  }

  > .Header__wrapper {
    height: 60vw;
    min-height: 300px;
  }
}

.About__header h1 {


  @include breakpoint(phablet) {
    padding: 0 100px;
  }

  @include breakpoint(laptop) {
    bottom: -40px;
    padding: 0 100px;
  }
}


.About__feature-item {
  margin-bottom: rem(70);

  &:last-child {
    margin-bottom: 0;
  }
}

@include breakpoint(laptop) {
  .About__feature-item {
    display: flex;
  }
}
