.Footer {}

.Footer__content {
  @include wrapper(large);

  padding-top: rem(70);
  padding-bottom: rem(70);

  text-align: center;

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.Footer__logo {
  margin-bottom: rem(40);
  height: rem(24);

  @include breakpoint(tablet) {
    margin-bottom: 0;
  }
}

.Footer__links {
  @include list-unstyled;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(20);
  column-count: 2;
  column-gap: rem(0);
  text-align: center;
  font-weight: $font-weight-normal;
  text-transform: lowercase;

  @include breakpoint(tablet) {
    @include list-inline;
    column-count: 1;
    margin-bottom: 0;
  }
}

.Footer__link {
  margin-bottom: rem(20);

  @include breakpoint(tablet) {
    margin-right: rem(20);
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.Footer__link a {
  color: $text-color;
}

.Footer__meta {
  font-size: em(13);
  font-weight: $font-weight-normal;
}
