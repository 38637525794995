@font-face {
  font-family: 'museo-sans';
  src: url('../webfonts/MuseoSans300.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../webfonts/MuseoSans500.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../webfonts/MuseoSans700.woff');
  font-weight: 700;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../webfonts/MuseoSans900.woff');
  font-weight: 900;
}

body {
  font-family: $body-font;
  font-weight: $font-weight-normal;
  font-size: $font-size;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#{headings} {
  font-family: $heading-font;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover {
    color: darken($teal, 10%);
  }
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
}

h1, .h1 {
  font-size: rem(30);
  line-height: rem(40);
  font-weight: $font-weight-bold;
}

h2, .h2 {
  font-size: rem(26);
  line-height: rem(38);
  font-weight: $font-weight-semibold;
}

h3, .h3 {
  font-size: rem(22);
  line-height: rem(32);
  font-weight: $font-weight-semibold;
}

h4, .h4 {
  font-size: rem(17);
  line-height: rem(29);
  font-weight: $font-weight-semibold;
}

h5, .h5 {
  font-size: rem(16);
  line-height: rem(27);
  font-weight: $font-weight-semibold;
}

p {
  font-size: rem(16);
  line-height: rem(27);
  font-weight: $font-weight-normal;
}

small {
  font-size: rem(12);
  line-height: rem(20);
}

.fs-small {
  font-size: rem(15);
  line-height: rem(22);
}

@include breakpoint(laptop) {
  body {
    font-size: $font-size-desktop;
  }

  h1, .h1 {
    font-size: rem(48);
    line-height: rem(70);
    font-weight: $font-weight-bold;
  }

  h2, .h2 {
    font-size: rem(40);
    line-height: rem(54);
    font-weight: $font-weight-semibold;
  }

  h3, .h3 {
    font-size: rem(32);
    line-height: rem(45);
    font-weight: $font-weight-semibold;
  }

  h4, .h4 {
    font-size: rem(20);
    line-height: rem(32);
    font-weight: $font-weight-semibold;
  }

  h5, .h5 {
    font-size: rem(16);
    line-height: rem(28);
    font-weight: $font-weight-semibold;
  }

  p {
    font-size: rem(18);
    line-height: rem(30);
    font-weight: $font-weight-normal;
  }

  small {
    font-size: rem(14);
    line-height: rem(22);
  }
}

/*

Font utilities

*/

// Font sizes

@mixin font-size-body {
  font-size: 1rem;

  @include breakpoint(laptop) {
    font-size: $font-size-desktop;
  }
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

// Text colors

.text-muted {
  color: $muted;
}
