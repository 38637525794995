/*

Grid

*/

// rows

.Grid {
  display: flex;
}

// columns

.Grid > * {
  flex: 1;
  flex-grow: 4;
  padding-left: 1rem;
  padding-right: 1rem;

  &:first-child {
    margin-left: -1rem;
  }

  &:last-child {
    margin-right: -1rem;
  }

  &.Column--light {
    flex-grow: 2;
  }

  &.Column--heavy {
    flex-grow: 8;
  }
}

/*

Wrappers

*/

@mixin wrapper ($size: 'medium') {
  margin: 0 auto;
  padding: rem(70) 2rem;

  @if $size == small {
    max-width: rem(650);
  }
  @else if $size == medium {
    max-width: rem(964);
  }
  @else if $size == large {
    max-width: rem(1200)
  }
}

.Wrapper {
  @include wrapper(medium);
}

.Wrapper--small {
  @include wrapper(small)
}

.Wrapper--medium {
  @include wrapper(medium)
}

.Wrapper--large {
  @include wrapper(large)
}
