.Button {
  @include font-size-body;

  box-shadow: 0 8px 23px 0 rgba(0, 0, 0, .25);
  border-radius: 100px;
  border: 0;
  outline: 0;
  font-weight: $font-weight-semibold;

  &:active {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .20);
    transform: translateY(1px);
  }

}

/*

Button sizes

*/

.Button--sm {
  padding: rem(8) rem(25);
}

.Button--md {
  padding: rem(13) rem(40);
}

/*

Button variants

*/

.Button.Button--dark {
  color: #fff;
  background-color: $teal;
  background-image: radial-gradient(-28% -232%, $teal);

  &[disabled] {
    color: rgba(#fff, .25);
  }

  &:hover {
    background-color: darken($teal, 5%);
  }
}

.Button.Button--light {
  color: $text-color;
  background-image: linear-gradient(to right, #f6f6f6 48%, $white-gold 100%);

  &[disabled] {
    color: rgba(#424242, .15);
  }

  &:hover {
    background-image: linear-gradient(to right, #eee 48%, darken($white-gold, 10%) 100%);
  }
}
