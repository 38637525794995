.contact .Header__wrapper {
  height: auto;
  min-height: auto;
}

.Contact__bg {
  @include bg-image('/images/contact/bg@2x.jpg');
  background-size: cover;
  background-position: 50%;
}

.Contact__header-icon {
  @include breakpoint(phablet) {
    margin-right: auto;
  }
}

.img-white {
  margin: 0 auto;
  width: 113px;
  height: auto;
}

%Contact__input-addon {
  padding: 0;
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: background-image 0.2s;
}

.Contact__name-input-addon {
  @extend %Contact__input-addon;
  @include bg-image('/images/contact/person.svg');
}

.is-active .Contact__name-input-addon,
.Contact__name-input-addon:before
{
  @include bg-image('/images/contact/person-active.svg')
}

.Contact__email-input-addon {
  @extend %Contact__input-addon;
  @include bg-image('/images/contact/email.svg')
}

.is-active .Contact__email-input-addon,
.Contact__email-input-addon:before {
  @include bg-image('/images/contact/email-active.svg')
}

.Contact__phone-input-addon {
  @extend %Contact__input-addon;
  @include bg-image('/images/contact/phone.svg')
}

.is-active .Contact__phone-input-addon,
.Contact__phone-input-addon:before {
  @include bg-image('/images/contact/phone-active.svg')
}

.Contact__message-input-addon {
  @extend %Contact__input-addon;
  @include bg-image('/images/contact/message.svg')
}

.is-active .Contact__message-input-addon,
.Contact__message-input-addon:before {
  @include bg-image('/images/contact/message-active.svg');
}


.Contact__map #map {
  height: 460px;
}

#contact-form #message {
  border: 1px solid $teal;
  border-radius: $input-border-radius;

  > * {
    border: none;
  }

  .InputAddOn__item {
    height: 50px;
    border: 1px solid $teal;
    border-radius: 0;
    border-bottom-right-radius: $input-border-radius;
    border-left: none;
    border-top: none;
  }
}

.InputAddOn__item > *:before {
  display: block;
  content: '';
}
