.Feature__item {
  margin-bottom: rem(70);

  &:last-child {
    margin-bottom: 0;
  }
}

.Feature__media > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: rem(180);
}

@include breakpoint(phablet) {
  .Feature__item {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .Feature__media {
    flex: 1;
    flex-grow: 1;
  }

  .Feature__content {
    flex: 1;
    flex-grow: 3;
    margin-left: rem(100);
  }
}
