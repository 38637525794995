/*

Header

*/

.Features__header .Header__wrapper {
  height: auto;
}

.Features__header .Header__content {
  padding-top: rem(30);
  padding-bottom: 8rem;

  @media (min-width: $bp-lg) {
    padding-bottom: 0;
  }
}

.Features__header .Header__wrapper {
  @media (min-width: $bp-lg) {
    padding-bottom: 0;
  }
}

/*

Stripes

*/

.Feature__stripe {
  background-image: linear-gradient(-180deg, $white-gold 70%, #fff 100%);
}

.Feature__stripe:first-child {
  padding-top: rem(80);
}

/*

Student/teacher toggle

*/

.student-teacher-toggle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;

  display: flex;
  color: $muted;
  border-radius: 11px;
  box-shadow: 0 8px 23px 0 rgba(0, 0, 0, .15);

  > * {
    cursor: pointer;
    padding: rem(25) rem(40);
    background-color: #eff2f3;
    border: 1px solid rgba(18, 111, 125, 0.31);
    user-select: none;
    transition: all .2s;

    &:hover {
      color: #333;

      img {
        opacity: 1;
      }
    }
  }

  img {
    opacity: .5;
    transition: opacity .2s;
  }

  .student {
    border-radius: 11px 0 0 11px;
  }

  .teacher {
    border-radius: 0 11px 11px 0;
  }

  .is-selected {
    position: relative;
    background-color: #fff;
    border-color: $teal;
    color: $text-color;
    transition: all .2s;

    &:after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include bg-image('/images/checkmark.svg');
      background-position: 70% 20%;
      background-size: 20px;
    }
  }

  .is-selected img {
    opacity: 1;
  }
}

@include breakpoint(phablet) {
  .student-teacher-toggle .is-selected:after {
    background-position: 75% 20%;
    background-size: 25px;
  }
}

@include breakpoint(tablet) {
  .student-teacher-toggle .is-selected:after {
    background-position: 82% 20%;
    background-size: 35px;
  }
}

/*

Animation

*/

#animation-container {
  position: relative;
  height: 550px;

  @include breakpoint(laptop) {
    width: 500px;
  }

  @include breakpoint(desktop) {
    height: 900px;
    width: 650px;
  }
}

#animation-text {
  @include breakpoint(tablet) {
    width: 420px;
    float: right;
    margin-top: 60px;
  }
}

#animation-loading {
  position: absolute;
  top: 100px;
  left: 50%;
  margin: 0 0 0 -20px;

  @include breakpoint(desktop) {
    top: 200px;
    margin: 0 0 0 -65px;
  }
}
