.Header {}

.Header--light {}

.Header--dark {
  background-color: $teal;
  color: #fff;
}

.Header__wrapper {
  @include wrapper(large);

  height: 750px;
  padding-top: rem(30);
  padding-bottom: rem(30);

  @include breakpoint(laptop) {
    height: 860px;
  }
}

.Header__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.Header__logo {
  height: rem(21);
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(laptop) {
    height: rem(36);
    margin: 0;
  }
}

.Header__content {
  position: relative;
  height: 100%;
  text-align: center;
}
