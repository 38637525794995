.jobs .Header {
  @include bg-image('/images/jobs/header-bg.jpg');
  background-size: cover;
  background-position: center;

  .Header__content {
    height: 80%;
  }
}

.Jobs__job-openings {
  position: relative;
  margin-top: -230px;

  > * {
    margin-bottom: rem(25);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include breakpoint(laptop) {
    display: flex;

    > * {
      margin-right: rem(25);
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
