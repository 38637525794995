/*

Color

*/

.color-white {
  color: #fff;
}

.color-teal {
  color: $teal;
}

.color-white-gold {
  color: $white-gold;
}

.color-silver {
  color: $silver;
}

.color-charcoal {
  color: $charcoal;
}

.color-cyan {
  color: $cyan;
}

.bg-transparent {
  background-color: transparent;
}

/*

Background color

*/

.bg-teal {
  background-color: $teal;
}

.bg-white-gold {
  background-color: $white-gold;
}

.bg-silver {
  background-color: $silver;
}

.bg-charcoal {
  background-color: $charcoal;
}

.bg-cyan {
  background-color: $cyan;
}

.bg-teal-gradient {
  background-image: linear-gradient(-228deg, #2399A4 0%, #1A828F 43%, #1A828F 54%, #126F7D 100%);
}

.bg-teal-alpha {
  background-color: rgba($teal, .20);
}

/*

Text

*/

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.link-unstyled {
  color: currentColor;
  &:hover {
    color: currentColor;
  }
}

.fw-normal {
  font-weight: $font-weight-normal;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

/*

Spacing

*/

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*

Layout

*/

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }
.display-none { display: none !important;}

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table
}
.clearfix:after { clear: both }

/* Position */

.relative { position: relative }
.absolute { position: absolute }
.fixed    { position: fixed }

.top-0    { top: 0 }
.right-0  { right: 0 }
.bottom-0 { bottom: 0 }
.left-0   { left: 0 }

/* Sizes */

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

/* Padding */

.p0  { padding: 0 }
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { padding:        $space-1 }
.pt1 { padding-top:    $space-1 }
.pr1 { padding-right:  $space-1 }
.pb1 { padding-bottom: $space-1 }
.pl1 { padding-left:   $space-1 }
.py1 { padding-top:    $space-1; padding-bottom: $space-1 }
.px1 { padding-left:   $space-1; padding-right:  $space-1 }

.p2  { padding:        $space-2 }
.pt2 { padding-top:    $space-2 }
.pr2 { padding-right:  $space-2 }
.pb2 { padding-bottom: $space-2 }
.pl2 { padding-left:   $space-2 }
.py2 { padding-top:    $space-2; padding-bottom: $space-2 }
.px2 { padding-left:   $space-2; padding-right:  $space-2 }

.p3  { padding:        $space-3 }
.pt3 { padding-top:    $space-3 }
.pr3 { padding-right:  $space-3 }
.pb3 { padding-bottom: $space-3 }
.pl3 { padding-left:   $space-3 }
.py3 { padding-top:    $space-3; padding-bottom: $space-3 }
.px3 { padding-left:   $space-3; padding-right:  $space-3 }

.p4  { padding:        $space-4 }
.pt4 { padding-top:    $space-4 }
.pr4 { padding-right:  $space-4 }
.pb4 { padding-bottom: $space-4 }
.pl4 { padding-left:   $space-4 }
.py4 { padding-top:    $space-4; padding-bottom: $space-4 }
.px4 { padding-left:   $space-4; padding-right:  $space-4 }

.p5  { padding:        $space-5 }
.pt5 { padding-top:    $space-5 }
.pr5 { padding-right:  $space-5 }
.pb5 { padding-bottom: $space-5 }
.pl5 { padding-left:   $space-5 }
.py5 { padding-top:    $space-5; padding-bottom: $space-5 }
.px5 { padding-left:   $space-5; padding-right:  $space-5 }

/* Margin */

.ma { margin: auto }
.mla { margin-right: auto }
.mra { margin-right: auto }
.mba { margin-bottom: auto }
.mla { margin-left:   auto }
.mxa { margin-left:   auto; margin-right: auto }
.mya { margin-top:    auto; margin-bottom: auto }

.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.mx0 { margin-left:   0; margin-right:  0 }
.my0 { margin-top:    0; margin-bottom: 0 }

.m1  { margin:        $space-1 }
.mt1 { margin-top:    $space-1 }
.mr1 { margin-right:  $space-1 }
.mb1 { margin-bottom: $space-1 }
.ml1 { margin-left:   $space-1 }
.mx1 { margin-left:   $space-1; margin-right:  $space-1 }
.my1 { margin-top:    $space-1; margin-bottom: $space-1 }

.m2  { margin:        $space-2 }
.mt2 { margin-top:    $space-2 }
.mr2 { margin-right:  $space-2 }
.mb2 { margin-bottom: $space-2 }
.ml2 { margin-left:   $space-2 }
.mx2 { margin-left:   $space-2; margin-right:  $space-2 }
.my2 { margin-top:    $space-2; margin-bottom: $space-2 }

.m3  { margin:        $space-3 }
.mt3 { margin-top:    $space-3 }
.mr3 { margin-right:  $space-3 }
.mb3 { margin-bottom: $space-3 }
.ml3 { margin-left:   $space-3 }
.mx3 { margin-left:   $space-3; margin-right:  $space-3 }
.my3 { margin-top:    $space-3; margin-bottom: $space-3 }

.m4  { margin:        $space-4 }
.mt4 { margin-top:    $space-4 }
.mr4 { margin-right:  $space-4 }
.mb4 { margin-bottom: $space-4 }
.ml4 { margin-left:   $space-4 }
.mx4 { margin-left:   $space-4; margin-right:  $space-4 }
.my4 { margin-top:    $space-4; margin-bottom: $space-4 }

.mxn1 { margin-left: -$space-1; margin-right: -$space-1; }
.mxn2 { margin-left: -$space-2; margin-right: -$space-2; }
.mxn3 { margin-left: -$space-3; margin-right: -$space-3; }
.mxn4 { margin-left: -$space-4; margin-right: -$space-4; }

.ml-auto { margin-left: auto }
.mr-auto { margin-right: auto }
.mx-auto { margin-left: auto; margin-right: auto; }

/* Center on mobile */
.xs-text-center {
  @media (max-width: $bp-xs) {
    text-align: center;
  }
}

.text-white {
  color: #fff !important;
}

/* Hide */

.xs-hide {
  @media (max-width: $bp-xs) {
    display: none;
  }
}

.sm-hide {
  @media (min-width: $bp-xs) and (max-width: $bp-sm) {
    display: none;
  }
}

.md-hide {
  @media (min-width: $bp-sm) and (max-width: $bp-md) {
    display: none;
  }
}

.lg-hide {
  @media (min-width: $bp-md) and (max-width: $bp-lg) {
    display: none;
  }
}

.xlg-hide {
  @media (min-width: $bp-lg) {
    display: none;
  }
}
