$reflex-prefix: null;

// Grid
$reflex-columns: 12; // number of columns
$reflex-max-width: 100%; // max-width of grid

// Breakpoints
$reflex-xs: 480px;
$reflex-sm: 768px;
$reflex-md: 992px;
$reflex-lg: 1200px;
$reflex-xlg: 1600px;

// Spacing
$reflex-grid-spacing:       1em;

$reflex-cell-spacing-sm:    ($reflex-grid-spacing / 2);
$reflex-cell-spacing-md:    $reflex-grid-spacing;
$reflex-cell-spacing-lg:    ($reflex-grid-spacing * 2);
