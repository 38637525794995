.Stripe {
  width: 100%;
}

.Stripe--light {
  background-color: #fff;
}

.Stripe--dark {
  background-color: $brand-primary;
  color: #fff;
}

.Stripe__title {
  text-align: center;
}

.Stripe__content > *:first-child {
  margin-top: 0;
}

.Stripe__content > *:last-child {
  margin-bottom: 0;
}
