/*

Header

*/

.Home__header {
  position: relative;
  background-image: url('/images/home/header-bg.svg'); // fallback
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 90%;

  background-image: linear-gradient(-228deg, #2399A4 0%, #1A828F 43%, #1A828F 54%, #126F7D 100%), url('/images/home/header-bg.svg');

  @include breakpoint(tablet) {
    background-image: url('/images/home/header-bg-desktop.svg');
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
}

#cursor {
  margin-right: 3px;
  display: inline-block;
  border-left: 3px solid #fff;
  opacity: .6;

  &.animate {
    animation: fadeIn .5s infinite alternate;
  }
}

.typewriter {
  display: inline-block;
  margin: 0 3px 0 8px;
  position: relative;
  white-space: nowrap;
  font-weight: bold;
}

.Home__header h1 {
  height: rem(80);

  > span:first-child {
    display: block;

    @include breakpoint(phablet) {
      display: inline;
    }
  }
}

.Home__header .Header__content {
  padding-top: rem(30);
}

.Home__header-app {
  display: block;
  margin-top: rem(50);
}

.Home__header-app img {
  height: 500px;
  box-shadow: 0 8px 23px 0 rgba(#632626, .10);
  border-radius: 10px; // HACK: to get border-radius to work properly
  overflow: hidden;

  @include breakpoint(laptop) {
    height: 600px;
    border-radius: 5px; // HACK: to get border-radius to work properly
  }
}

/*

Content

*/

.Home__projectcampus-stripe {
  margin-top: 2rem;

  @include breakpoint(laptop) {
    margin-top: 5rem;
  }
}

.Home__logos-grid {
  margin-bottom: -50px !important;
}

.Home__logos-grid img {
  width: calc(50% - 60px);
  margin-right: 60px;
  margin-bottom: 50px;
  vertical-align: middle;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @include breakpoint(tablet) {
    width: calc(25% - 50px);
    margin-right: 50px;

    &:nth-child(2n) {
      margin-right: 50px;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
