$input-border-radius: 4px;
$input-padding-vertical: 13px;
$input-padding-horizontal: 13px;
$input-border-color: $teal;
$input-border-color-focus: $teal;

.Input {
  border-radius: $input-border-radius;
  border: 1px solid $teal;
  padding: 13px;
  background-color: transparent;
  margin-bottom: $space-2;
}

.Input[type='submit'] {
  color: rgba(#fff, .5);
  background-color: $teal;
  font-weight: $font-weight-semibold;
  padding: 13px 24px;
}

/*

Input addon

*/

.InputAddOn {
  display: flex;
  flex-direction: row;
  margin-bottom: $space-2;
  background: #fff;
}

.InputAddOn input {
  margin-bottom: 0;
}

.InputAddOn .InputAddOn__field {
  flex: 1;
  margin-bottom: 0;
}

.InputAddOn .InputAddOn__field:not(:first-child) {
  border-left: 0;
}

.InputAddOn .InputAddOn__field:not(:last-child) {
  border-right: 0;
}

.InputAddOn__item {
  // @extend %reset-button;

  display: flex;

  padding: $input-padding-vertical $input-padding-horizontal;

  transition: all .3s;

  border: 1px solid $input-border-color;

  align-items: center;
}

.is-active .InputAddOn__item {
  background-color: $teal;
}

.InputAddOn__field:first-child,
.InputAddOn__item:first-child {
  border-radius: $input-border-radius 0 0 $input-border-radius;
}

.InputAddOn__field:last-child,
.InputAddOn__item:last-child {
  border-radius: 0 $input-border-radius $input-border-radius 0;
}

.InputAddOn__field:focus + .InputAddOn__item {
  border-color: $input-border-color-focus;
}

#form-flash {
  position: fixed;
  top: 0px;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  line-height: 50px;
  background: #31a731;
  height: 70px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  box-shadow: 3px 3px 15px rgba(0,0,0,0.2);
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
  text-align: center;
}

#form-flash.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(50%);
}
