// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

// Disable horizontal scrolling
body {
  overflow-x: hidden;
}

/*

Media

*/

// Responsive images
img {
  max-width: 100%;
}

// Remove focus border

*:focus {
    outline: none;
}

iframe {
  border: 0;
}
