/*

  Desktop nav

*/

.Nav {
  @media (max-width: 1023px) {
    display: none;
  }
}

.Nav__items {
  @include list-inline;

  text-transform: lowercase;
}

.Nav__item {
  margin-right: rem(20);

  &:last-child {
    margin-right: 0;
  }
}

.Nav__item a {
  color: currentColor;
  padding-bottom: 8px;
}

.Nav__item.is-active a {
  border-bottom: 1px solid currentColor;
}

.SiteNav--light .Nav__item a {
  color: $text-color;
}

.SiteNav--dark .Nav__item a {
  color: #fff;
}

/*

  Mobile nav

*/

.MobileNav {
  position: fixed;
  background-color: rgba($charcoal, .98);
  left: rem(20);
  right: rem(20);
  top: 5rem;
  border-radius: 5px;
  z-index: 1000;

  @include breakpoint(laptop) {
    display: none;
  }

  &.is-hidden {
    display: none;
  }
}

.MobileNav__items {
  @include list-unstyled;
  font-size: rem(16);
  text-transform: lowercase;
}

.MobileNav__item a {
  display: block;
  padding: rem(20);
  text-align: center;
  color: #fff;
  border-bottom: 1px solid rgba(#fff, .04);
}

.MobileNav__toggle {
  @include button-unstyled;

  @include breakpoint(laptop) {
    display: none;
  }

  color: $teal;
  position: fixed;
  top: rem(20);
  right: rem(20);
  padding: rem(20);
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid $teal;
  z-index: 9999;

  display: flex;
  align-content: center;
}
